import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
    state: () => ({
        _user: {
        },
        _subscriptions: {},
    }),
    getters: {
        user: (state) => state._user,
        isAdmin: (state) => state._user.isAdmin,
        isLoggedIn: (state) => state._user && state._user.token,
        token: (state) => state._user && state._user.token,
        checkSubscription: (state) => (id) => {
            return state._user.isAdmin || (state._subscriptions[id] && new Date(state._subscriptions[id].subEndDate) > new Date())
        },
        subscriptions: (state) => state._subscriptions,
        isPremiumUser: (state) => state._user.isAdmin || Object.keys(state._subscriptions).length
    },
    actions: {
        login({ user, subscriptions }) {
            this._user = user
            if (subscriptions) {
                this.setSubscriptions(subscriptions)
            }
        },
        logout() {
            this._user = {}
            this._subscriptions = {}
        },
        setSubscriptions(subscriptions) {
            const idWise = {}
            subscriptions.forEach(element => {
                if (element.examId && element.examId.id) {
                    idWise[element.examId.id] = element
                }
            });
            this._subscriptions = { ...this._subscriptions, ...idWise }
        }
    },
    persist: {
        key: 'KREYA',
    },
})
