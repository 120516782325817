import { createApp } from "vue";
import { createPinia } from 'pinia';
import App from "./App.vue";
import router from "./router";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import "@mdi/font/css/materialdesignicons.css";

import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
// import { config as dotenvConfig } from "dotenv";

// Load environment variables from .env file
// dotenvConfig();


const pinia = createPinia();
pinia.use(piniaPluginPersistedstate)


const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: "mdi",
    },
});

const app = createApp(App)
app.use(pinia);
app.use(router);
app.use(vuetify);
app.config.errorHandler = (err, vm, info) => {
    // Handle the error
    console.log("caught global error");
    console.error(`Error: ${err.toString()}\nInfo: ${info}`);

    // Optionally, display a user-friendly message
    // You can show a modal, toast, or redirect to an error page
};

app.mount("#app")
