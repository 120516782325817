import { createRouter, createWebHistory } from "vue-router";
import { useUserStore } from "../store/user";

const routes = [
  {
    path: "/",
    component: () => import("../layouts/MainLayout.vue"),
    children: [
      {
        path: "/login",
        component: () => import("../pages/LoginPage.vue"),
      },
      {
        path: "/forgot-password",
        component: () => import("../pages/ForgotPassword.vue"),
      },
      {
        path: "/profile",
        component: () => import("../pages/ProfilePage.vue"),
      },
      {
        path: "/signup",
        component: () => import("../pages/SignupPage.vue"),
      },
      {
        path: "/",
        component: () => import("../pages/ExamsPage.vue"),
      },
      {
        path: "/exam",
        component: () => import("../pages/ExamsPage.vue"),
      },
      {
        path: "/wallet",
        component: () => import("../pages/WalletPage.vue"),
      },
      {
        path: "subjects/:examId",
        component: () => import("../pages/SubjectsPage.vue"),
      },
      {
        path: "updates/:examId",
        component: () => import("../pages/UpdatesPage.vue"),
      },
      {
        path: "questions/:subjectId",
        component: () => import("../pages//QuestionsPage.vue"),
      },
      {
        path: "links/:updateId",
        component: () => import("../pages/LinksPage.vue"),
      },
      // {
      //   path: "subscription",
      //   component: () => import("../pages/subscriptionPage.vue"),
      // },
      {
        path: "/:catchAll(.*)*",
        component: () => import("../pages/PageNotFound.vue"),
      },
    ],
  },
  {
    path: "/admin",
    component: () => import("../layouts/AdminLayout.vue"),
    children: [
      // {
      //   path: "",
      //   component: () => import("../pages/Admin/Exam/ExamCrud.vue"),
      // },
      {
        path: "exam",
        component: () => import("../pages/Admin/Exam/ExamCrud.vue"),
      },
      {
        path: "user",
        component: () => import("../pages/Admin/User/UserCrud.vue"),
      },
      {
        path: "payment",
        component: () => import("../pages/Admin/Payment/PaymentCrud.vue"),
      },
      {
        path: "subject",
        component: () => import("../pages/Admin/Subjects/SubjectCrud.vue"),
      },
      {
        path: "wallet",
        component: () => import("../pages/Admin/Wallet/WalletList.vue"),
      },
      {
        path: "",
        component: () => import("../pages/ProfilePage.vue"),
      },
      {
        path: "update",
        component: () => import("../pages/Admin/Updates/UpdateCrud.vue"),
      },
      {
        path: "question/:subjectId",
        component: () => import("../pages/Admin/Questions/QuestionCrud.vue"),
      },
      {
        path: "link/:updateId",
        component: () => import("../pages/Admin/Links/LinkCrud.vue"),
      },
      {
        path: "",
        component: () => import("../pages/ProfilePage.vue"),
      },
      {
        path: "/:catchAll(.*)*",
        component: () => import("../pages/PageNotFound.vue"),
      },
    ],
  },
  {
    path: "/:catchAll(.*)*",
    component: () => import("../pages/PageNotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/signup', '/forgot-password'];
  const authRequired = !publicPages.includes(to.path);
  const userStore = useUserStore(); // Instantiate the store
  const loggedIn = userStore.isLoggedIn; // Access isLoggedIn getter
  const isAdmin = userStore.isAdmin; // Access isLoggedIn getter

  if (authRequired && !loggedIn) {
    next('/login');
  }
  else if (to.path.startsWith('/admin') && !isAdmin) {
    next('/');
  } else {
    next();
  }
});

export default router;
