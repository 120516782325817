// src/stores/errorStore.js
import { defineStore } from 'pinia';

export const useSnackbarStore = defineStore('snackbarStore', {
    state: () => ({
        messages: [],
    }),
    actions: {
        addMessage(message) {
            this.messages.push(message);
        },
        removeMessage() {
            return this.messages.shift();
        },
    },
});
