<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    :color="snackbarColor"
    content-class="text-white"
    @input="onSnackbarInput"
  >
    {{ snackbarMessage }}
    <template v-slot:actions>
      <v-btn text @click="snackbar = false">Close</v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
import { ref, watch } from "vue";
import { useSnackbarStore } from "../store/snackbarStore";

const snackbarStore = useSnackbarStore();
const snackbar = ref(false);
const snackbarMessage = ref("");
const snackbarColor = ref("error");
const timeout = ref(2000); // Duration the snackbar is visible

watch(
  () => snackbarStore.messages.length,
  (newLength) => {
    if (newLength > 0 && !snackbar.value) {
      showMessage();
    }
  }
);

const showMessage = () => {
  const message = snackbarStore.removeMessage();
  if (message) {
    snackbarMessage.value = message.text;
    snackbar.value = true;
    snackbarColor.value = message.color || "error";
  }
};

const onSnackbarInput = (val) => {
  if (!val) {
    showMessage();
  }
};
</script>
